import Auth from '@aws-amplify/auth';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';

const initialState = {
  ecomStoreAdmins: { value: [], loading: false, error: null },
  ecomStores: { value: [], loading: false, error: null },
  ecomVendorAdmins: { value: [], loading: false, error: null },
  ecomVendors: { value: [], loading: false, error: null }
};

const ecommerceProvider = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: null,
          loading: true
        }
      };
    case 'success':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          loading: false
        }
      };
    case 'append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value,
            ...(Array.isArray(action.payload)
              ? action.payload
              : [action.payload])
          ],
          loading: false
        }
      };
    case 'replace':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            ...(Array.isArray(action.payload.value)
              ? action.payload.value
              : [action.payload.value])
          ],
          loading: false
        }
      };
    case 'remove':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload),
          loading: false
        }
      };
    case 'error':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: action.payload,
          loading: false
        }
      };
    default:
      return state;
  }
};

const getErrorMessage = (error) => {
  let errorMessage = 'Oops something went wrong!';
  if (error?.response && error.response.status === 409) {
    errorMessage = error.response.data?.error ?? errorMessage;
  }

  return errorMessage;
};

// #region Vendors
const fetchEcomVendors = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'ecomVendors'
    });

    const response = await sportsheadzApi.get('/api/staff/ecommerce/vendors', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    dispatch({
      type: 'success',
      stateName: 'ecomVendors',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'ecomVendors',
      payload: 'Oops something went wrong'
    });
  }
};

const createEcomVendor = (dispatch) => async (
  { name, domain, supportEmail, isDemoVendor, sandboxEnabled, fkCurrencyType },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.post(
      '/api/staff/ecommerce/vendors',
      {
        name,
        domain,
        supportEmail,
        sandboxEnabled,
        isDemoVendor,
        fkCurrencyType
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'ecomVendors',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'ecomVendors',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const updateEcomVendor = (dispatch) => async (
  pkEcomVendor,
  {
    name,
    domain,
    supportEmail,
    sandboxEnabled,
    isDemoVendor,
    fkCurrencyType,
    restoreVendor
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/ecommerce/admin/vendors/${pkEcomVendor}`,
      {
        name,
        domain,
        supportEmail,
        sandboxEnabled,
        isDemoVendor,
        fkCurrencyType,
        restoreVendor
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'ecomVendors',
      payload: {
        value: response.data,
        filter: (vendor) => vendor.pkEcomVendor !== pkEcomVendor
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'ecomVendors',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const deleteEcomVendor = (dispatch) => async (
  pkEcomVendor,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/staff/ecommerce/vendors/${pkEcomVendor}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'ecomVendors',
      payload: (vendor) => vendor.pkEcomVendor !== pkEcomVendor
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'ecomVendors',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const fetchEcomVendorAdmins = (dispatch) => async (pkEcomVendor) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'ecomVendorAdmins'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/ecommerce/vendors/admins/${pkEcomVendor}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'ecomVendorAdmins',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'ecomVendorAdmins',
      payload: 'Oops something went wrong'
    });
  }
};

const assignEcomVendorAdmins = (dispatch) => async (
  ecomVendorAdmins,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/staff/ecommerce/vendors/admins`,
      ecomVendorAdmins,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'ecomVendorAdmins',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback(getErrorMessage(e));
    }
  }
};
// #endregion

// #region Stores
const fetchEcomStores = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'ecomStores'
    });

    const response = await sportsheadzApi.get('/api/staff/ecommerce/stores', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    dispatch({
      type: 'success',
      stateName: 'ecomStores',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'ecomStores',
      payload: 'Oops something went wrong'
    });
  }
};

const createEcomStore = (dispatch) => async (
  {
    name,
    domain,
    supportEmail,
    isDemoStore,
    sandboxEnabled,
    fkCurrencyType,
    stockContentId
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.post(
      '/api/ecommerce/admin/stores',
      {
        name,
        domain,
        supportEmail,
        sandboxEnabled,
        isDemoStore,
        fkCurrencyType,
        stockContentId
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'ecomStores',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'ecomStores',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const createEcomStoreForAssociation = (dispatch) => async (
  { pkAssociation, adminUserPk, stockContentId },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/ecommerce/admin/stores/create-for-association`,
      {
        pkAssociation,
        adminUserPk,
        stockContentId
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const updateEcomStore = (dispatch) => async (
  pkEcomStore,
  {
    name,
    domain,
    supportEmail,
    sandboxEnabled,
    isDemoStore,
    fkCurrencyType,
    restoreStore
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/ecommerce/admin/stores/${pkEcomStore}`,
      {
        name,
        domain,
        supportEmail,
        sandboxEnabled,
        isDemoStore,
        fkCurrencyType,
        restoreStore
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'ecomStores',
      payload: {
        value: response.data,
        filter: (store) => store.pkEcomStore !== pkEcomStore
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'ecomStores',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const deleteEcomStore = (dispatch) => async (
  pkEcomStore,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/staff/ecommerce/stores/${pkEcomStore}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'ecomStores',
      payload: (store) => store.pkEcomStore !== pkEcomStore
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'ecomStores',
      payload: errorMessage
    });
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const fetchEcomStoreAdmins = (dispatch) => async (pkEcomStore) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'ecomStoreAdmins'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/ecommerce/stores/admins/${pkEcomStore}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'ecomStoreAdmins',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'ecomStoreAdmins',
      payload: 'Oops something went wrong'
    });
  }
};

const assignEcomStoreAdmins = (dispatch) => async (
  ecomStoreAdmins,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/staff/ecommerce/stores/admins`,
      ecomStoreAdmins,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'ecomStoreAdmins',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback(getErrorMessage(e));
    }
  }
};
// #endregion

export const { Provider, Context } = createDataProvider(
  ecommerceProvider,
  {
    assignEcomStoreAdmins,
    assignEcomVendorAdmins,
    fetchEcomStores,
    fetchEcomStoreAdmins,
    fetchEcomVendorAdmins,
    fetchEcomVendors,
    createEcomStore,
    createEcomStoreForAssociation,
    createEcomVendor,
    updateEcomStore,
    updateEcomVendor,
    deleteEcomStore,
    deleteEcomVendor
  },
  initialState
);
