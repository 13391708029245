import React, { useContext, useState } from 'react';
import { Badge, Card, Group, Image, Stack, Tabs, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { Context as EcommmerceContext } from '../../../providers/EcommerceProvider';
import EcomVendorAdminListView from './EcomVendorAdminListView';
import EcomVendorSettingsView from './EcomVendorSettingsView';

const VIEW_TABS = [
  {
    value: 'ADMINS',
    label: 'Admins'
  },
  {
    value: 'SETTINGS',
    label: 'Settings'
  }
];

const EcomVendorDetails = () => {
  const { pkEcomVendor } = useParams();
  const { state } = useContext(EcommmerceContext);
  const [activeTab, setActiveTab] = useState('ADMINS');
  const ecomVendor = state.ecomVendors.value.find(
    (a) => a.pkEcomVendor.toString() === pkEcomVendor
  );

  return ecomVendor ? (
    <Card
      shadow="lg"
      sx={{
        flex: 1,
        border: 'solid 1px lightgrey',
        padding: 15,
        gap: 5,
        alignSelf: 'stretch'
      }}
    >
      <Group style={{ justifyContent: 'space-between' }}>
        <Group sx={{ flexWrap: 'nowrap' }}>
          <Image
            fit="contain"
            height={50}
            src={ecomVendor.logoImageUrl ?? null}
            width={50}
          />
          <Text sx={{ fontSize: 18 }} weight={700}>
            {ecomVendor.name}
          </Text>
        </Group>
        <Group sx={{ gap: 8 }}>
          {ecomVendor.deleted ? (
            <Badge color="red" variant="filled">
              Deleted
            </Badge>
          ) : ecomVendor.sandboxEnabled ? (
            <Badge color="yellow" variant="filled">
              Sandbox
            </Badge>
          ) : (
            <Badge color="green" variant="filled">
              Live
            </Badge>
          )}
          {ecomVendor.isDemoVendor && (
            <Badge color="blue" variant="filled">
              DEMO MODE
            </Badge>
          )}
        </Group>
      </Group>

      <Stack sx={{ gap: 10 }}>
        <Tabs onTabChange={setActiveTab} value={activeTab}>
          <Stack sx={{ gap: 20 }}>
            <Tabs.List>
              {VIEW_TABS.map((a) => (
                <Tabs.Tab key={a.value} value={a.value}>
                  {a.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Stack>
        </Tabs>
        {activeTab === 'ADMINS' ? (
          <EcomVendorAdminListView ecomVendor={ecomVendor} />
        ) : (
          <EcomVendorSettingsView ecomVendor={ecomVendor} />
        )}
      </Stack>
    </Card>
  ) : (
    <></>
  );
};

EcomVendorDetails.propTypes = {};

export default EcomVendorDetails;
